import { AllInbox } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import {
  UnifiedInbox
} from "../loadables";
import { Route } from "./routeInterface";

export const unifiedInboxRoutes: Array<Route> = [
  {
    key: "unified-inbox-route",
    label: "Inbox",
    path: "inbox",
    enabled: true,
    component: UnifiedInbox,
    isTopTab: true,
    isInternalTesting: true,
    order: 1,
    tabInfo: {
      id: "inbox",
      startIcon: <Icon component={AllInbox} fontSize="small" />,
      endIcon: null
    },
    leastRole: COLLABORATOR_ROLE,
    expandOnOpen: true
  },
  {
    key: "unified-inbox-route-detail",
    label: "Inbox details",
    path: "inbox/",
    enabled: true,
    isInternalTesting: true,
    component: UnifiedInbox,
    leastRole: COLLABORATOR_ROLE,
  }
];
